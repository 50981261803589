import React from "react";
import Quiz from "./qcm/Quiz";
import "./style/qcm.scss";

function QuestionPage() {
  return (
    <div className="qcm-page">
      <h1>QCM</h1>
      <Quiz />
      {/*  <button onClick={() => navigate("/")}>Retour au jeu</button> */}
    </div>
  );
}

export default QuestionPage;
