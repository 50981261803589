import axios from "axios";

const apiKey = process.env.REACT_APP_BREVO_KEY;

if (!apiKey) {
  throw new Error("API key is not defined");
}

const brevoApi = axios.create({
  baseURL: "https://api.brevo.com/v3",
  headers: {
    "api-key": apiKey,
    "Content-Type": "application/json",
  },
});

const handleError = (error) => {
  const errorMsg = error.response?.data || error.message;
  console.error("Brevo API Error:", errorMsg);
  throw new Error(errorMsg);
};

const getContactByEmail = async (email) => {
  try {
    const response = await brevoApi.get(`/contacts/${email}`);
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      return null;
    } else {
      handleError(error);
    }
  }
};

export const addContactToList = async (contactData, listId) => {
  const data = {
    email: contactData.email,
    attributes: {
      PRENOM: contactData.firstName,
      NOM: contactData.lastName,
      OPT_IN: contactData.optIn,
    },
    listIds: [listId],
  };

  console.log("Data being sent to Brevo API:", JSON.stringify(data, null, 2));

  try {
    const response = await brevoApi.post("/contacts", data);
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.code === "duplicate_parameter") {
      console.log("Contact already exists, updating contact...");
      try {
        const contact = await getContactByEmail(contactData.email);
        if (!contact) {
          throw new Error("Contact not found after duplicate error.");
        }

        // Merge existing list IDs with the new list ID to ensure the contact is in the new list
        const updatedListIds = Array.from(
          new Set([...contact.listIds, listId])
        );

        const updateData = {
          attributes: {
            PRENOM: contactData.firstName,
            NOM: contactData.lastName,
            OPT_IN: contactData.optIn,
          },
          listIds: updatedListIds,
        };

        // Update contact information
        const updateResponse = await brevoApi.put(
          `/contacts/${contact.id}`,
          updateData
        );
        return updateResponse.data;
      } catch (updateError) {
        handleError(updateError);
      }
    } else {
      handleError(error);
    }
  }
};

export const sendNotification = async (email, templateId, params) => {
  try {
    const response = await brevoApi.post("/smtp/email", {
      to: [{ email: email }],
      templateId: templateId,
      params: params,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
