import React, { useState, useEffect, useRef } from "react";
import DialogueBox from "./components/DialogueBox";
import interactiveDataJson from "./data/interactiveData.json";
import incivilityElementsJson from "./data/incivilityElements.json";
import unorderInteractiveData from "./data/unorderInteractiveData.json";

function InteractiveImage({ isDragging, setIsDialogueOpen, isDialogueOpen }) {
  const [dialogueData, setDialogueData] = useState({
    show: false,
    content: "",
    key: 0,
    audioSrc: null,
    showButton: false,
    slides: [],
  });

  const [interactiveItems, setInteractiveItems] = useState([]);
  const [incivilityElements, setIncivilityElements] = useState([]);
  const [unorderInteractiveItems, setUnorderInteractiveItems] = useState([]);
  const [foundItems, setFoundItems] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(1);
  const [showIncivilityElements, setShowIncivilityElements] = useState(false);
  const [foundIncivilities, setFoundIncivilities] = useState([]);
  const [allIncivilitiesFound, setAllIncivilitiesFound] = useState(false);
  const [currentIncivility, setCurrentIncivility] = useState(null);
  const [finalAgentTextShown, setFinalAgentTextShown] = useState(false);
  const audioRefs = useRef([]);
  const lastClickedItem = useRef(null);
  const agentFirstInteraction = useRef(false); // Track first interaction with the agent

  // Find the ID of the last interactive item
  const lastInteractiveItemId =
    interactiveDataJson[interactiveDataJson.length - 1].id;

  useEffect(() => {
    setInteractiveItems(interactiveDataJson);
    setIncivilityElements(incivilityElementsJson);
    setUnorderInteractiveItems(unorderInteractiveData);
  }, []);

  const stopAllAudio = () => {
    audioRefs.current.forEach((audio) => {
      if (audio) {
        audio.pause();
        audio.currentTime = 0; // Reset audio to start
      }
    });
    audioRefs.current = [];
  };

  const handleImageClick = (itemId) => {
    if (isDragging || isDialogueOpen) return; // Prevent click if dragging or dialogue is open

    const clickedItem = interactiveItems.find((item) => item.id === itemId);
    if (!clickedItem) return;

    const isCorrectOrder = clickedItem.order === currentOrder;

    stopAllAudio();

    if (clickedItem.showDialogue) {
      const newKey = dialogueData.show
        ? dialogueData.key + 1
        : dialogueData.key;
      let content, audioSrc, slides;

      if (clickedItem.id === 4) {
        // Handling the security agent
        if (allIncivilitiesFound && !finalAgentTextShown) {
          content =
            "Bravo, tu as surmonté ce défi les yeux fermés ! Mais ne te repose pas sur tes lauriers : il y a encore bien des incivilités à éviter. Si tu interroges les autres passagers, peut-être qu’ils te donneront d’autres exemples.";
          audioSrc = "./audio/personnage-3-audio.mp3";
          slides = [
            { text: content, audioSrc: "./audio/personnage-3-audio.mp3" },
            {
              text: "PROCHAIN ARRÊT : l’école Pauline Kergomard.",
              audioSrc: "./audio/personnage-3-audio.mp3",
            },
            {
              text: "Ah, c’est ton arrêt ! Pour gagner du temps, les bus ne s'arrêtent pas toujours aux arrêts où personne ne souhaite descendre. Tu dois donc appuyer sur le bouton pour demander l’arrêt au conducteur.",
              audioSrc: "./audio/personnage-3-audio.mp3",
            },
            {
              text: "Il y en a plusieurs : sur les barres métalliques verticales proches des portes et à d’autres endroits près des sièges. Évite de le faire au dernier moment pour ne pas surprendre le chauffeur.",
              audioSrc: "./audio/personnage-3-audio.mp3",
            },
            {
              text: "Un dernier mot : ne jette pas ton ticket usagé n’importe où s’il te plait ! On peut les recycler s’ils sont jetés à la poubelle.",
              audioSrc: "./audio/personnage-3-audio.mp3",
            },
          ];
          setFinalAgentTextShown(true);
        } else if (isCorrectOrder && !finalAgentTextShown) {
          if (showIncivilityElements) {
            content =
              "Pas facile, hein ? N’hésite pas à t’aider des indices. Et ne perd pas ton sang froid, ce n’est pas agréable d’avoir un râleur dans le bus !";
            audioSrc = "./audio/personnage-3-audio.mp3";
            slides = [{ text: content, audioSrc }];
          } else {
            content = clickedItem.correctContent[0].text;
            audioSrc = clickedItem.correctContent[0].audioSrc;
            slides = clickedItem.correctContent;
            setShowIncivilityElements(true); // Start the search for incivilities
            agentFirstInteraction.current = true; // Mark first interaction with agent
          }
        } else {
          content = clickedItem.incorrectContent[0].text;
          audioSrc = null;
          slides = clickedItem.incorrectContent;
        }
      } else {
        content = isCorrectOrder
          ? clickedItem.correctContent[0].text
          : clickedItem.incorrectContent[0].text;
        audioSrc = isCorrectOrder
          ? clickedItem.correctContent[0].audioSrc
          : clickedItem.incorrectContent[0].audioSrc;
        slides = isCorrectOrder
          ? clickedItem.correctContent
          : clickedItem.incorrectContent;
      }

      setDialogueData({
        show: true,
        content,
        key: newKey,
        audioSrc,
        showButton: isCorrectOrder ? clickedItem.showButtonNext : false,
        slides,
      });

      setIsDialogueOpen(true); // Set dialogue open state to true

      if (isCorrectOrder) {
        setFoundItems((prevItems) => [...prevItems, clickedItem.id]);
        lastClickedItem.current = clickedItem;
      }
    } else if (clickedItem.audioSrc) {
      const audio = new Audio(clickedItem.audioSrc);
      audioRefs.current.push(audio);
      audio.play();
    }
  };

  const handleUnorderInteractiveClick = (itemId) => {
    if (isDragging || isDialogueOpen) return; // Prevent click if dragging or dialogue is open

    const clickedItem = unorderInteractiveItems.find(
      (item) => item.id === itemId
    );
    if (!clickedItem) return;

    stopAllAudio();

    if (clickedItem.showDialogue) {
      setDialogueData((prevData) => ({
        show: true,
        content: clickedItem.content[0].text,
        key: prevData.key + 1,
        audioSrc: clickedItem.content[0].audioSrc,
        showButton: false,
        slides: clickedItem.content,
      }));

      setIsDialogueOpen(true); // Set dialogue open state to true
    } else if (clickedItem.audioSrc) {
      const audio = new Audio(clickedItem.audioSrc);
      audioRefs.current.push(audio);
      audio.play();
    }
  };

  const handleIncivilityClick = (id) => {
    if (isDragging || isDialogueOpen) return; // Prevent click if dragging or dialogue is open

    const clickedElement = incivilityElements.find(
      (element) => element.id === id
    );
    if (!clickedElement) return;

    if (!showIncivilityElements) return;

    // Check if the incivility has already been found
    if (foundIncivilities.includes(id)) return;

    stopAllAudio();

    setCurrentIncivility(id); // Track the current incivility being viewed

    setDialogueData((prevData) => ({
      show: true,
      content: clickedElement.slides[0].text,
      key: prevData.key + 1,
      audioSrc: clickedElement.slides[0].audioSrc,
      showButton: false,
      slides: clickedElement.slides,
    }));

    setIsDialogueOpen(true); // Set dialogue open state to true
  };

  const handleClose = () => {
    stopAllAudio();
    setDialogueData((prevData) => ({
      ...prevData,
      show: false,
    }));

    setIsDialogueOpen(false); // Set dialogue open state to false

    // Validate the current incivility if all slides have been viewed
    if (currentIncivility !== null) {
      setFoundIncivilities((prevItems) => [...prevItems, currentIncivility]);
      setCurrentIncivility(null);

      if (foundIncivilities.length + 1 === incivilityElements.length) {
        console.log("Toutes les incivilités ont été trouvées !");
        setAllIncivilitiesFound(true);
        setShowIncivilityElements(false);
      }
      return; // Do not advance order on incivility dialogues
    }

    // Advance the order only if the last clicked item was in the correct order
    if (lastClickedItem.current) {
      if (lastClickedItem.current.id === 4) {
        // Special handling for the security agent (id 4)
        if (allIncivilitiesFound && agentFirstInteraction.current) {
          setCurrentOrder((prevOrder) => prevOrder + 1);
        }
      } else if (
        lastClickedItem.current.order === currentOrder &&
        lastClickedItem.current.id !== lastInteractiveItemId // Check if it is not the last item
      ) {
        setCurrentOrder((prevOrder) => prevOrder + 1);
      }
      lastClickedItem.current = null;
    }
  };

  const nextItem = interactiveItems.find((item) => item.order === currentOrder);

  console.log("Current Order:", currentOrder); // Debugging current order
  console.log("Next Item:", nextItem); // Debugging next item
  console.log("Found Items:", foundItems); // Debugging found items

  return (
    <div style={{ position: "relative", cursor: "pointer" }}>
      {interactiveItems.map((item) => (
        <img
          draggable="false"
          key={`interactive-${item.id}`}
          src={item.imgSrc}
          alt={`Interactive item ${item.id}`}
          style={{
            position: "absolute",
            left: `${item.coords.x}px`,
            top: `${item.coords.y}px`,
            width: `${item.width}px`,
            height: "auto",
          }}
          onClick={() => handleImageClick(item.id)}
        />
      ))}
      {incivilityElements.map((element) => (
        <img
          draggable="false"
          key={`incivility-${element.id}`}
          src={element.imgSrc}
          alt={`Incivility element ${element.id}`}
          style={{
            position: "absolute",
            left: `${element.coords.x}px`,
            top: `${element.coords.y}px`,
            width: `${element.width}px`,
            height: "auto",
            pointerEvents: showIncivilityElements ? "auto" : "none",
          }}
          onClick={() => handleIncivilityClick(element.id)}
        />
      ))}
      {unorderInteractiveItems.map((item) => (
        <img
          draggable="false"
          key={`unorder-${item.id}`}
          src={item.imgSrc}
          alt={`Non-interactive item ${item.id}`}
          style={{
            position: "absolute",
            left: `${item.coords.x}px`,
            top: `${item.coords.y}px`,
            width: `${item.width}px`,
            height: "auto",
          }}
          onClick={() => handleUnorderInteractiveClick(item.id)}
        />
      ))}
      {dialogueData.show && (
        <DialogueBox
          key={dialogueData.key}
          onClose={handleClose}
          showButton={dialogueData.showButton}
          slides={dialogueData.slides}
          stopAllAudio={stopAllAudio}
        />
      )}
      <div className="indice-bloc">
        {nextItem && !showIncivilityElements && <p>{nextItem.hint}</p>}
        {showIncivilityElements && (
          <ul className="incivility-hints">
            {incivilityElements.map((element) => (
              <li
                key={element.id}
                style={{
                  textDecoration: foundIncivilities.includes(element.id)
                    ? "line-through"
                    : "none",
                }}
              >
                {element.text}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default InteractiveImage;
