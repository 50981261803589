// src/FormPage.js
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { addContactToList, sendNotification } from "./form/brevoApi";
import FormInput from "./form/FormInput";
import "./style/form.scss";

function FormPage() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    acceptNewsletter: false,
  });

  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState(null);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let tempErrors = {};
    ["firstName", "lastName", "email"].forEach((field) => {
      if (!formData[field]) {
        tempErrors[field] = "Ce champ est requis";
      }
    });
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = "Email non valide";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmissionError(null); // Reset the error message on new submission

    if (validateForm()) {
      if (!captchaToken) {
        setSubmissionError("Veuillez compléter le reCAPTCHA");
        return;
      }

      const contactData = {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        optIn: formData.acceptNewsletter,
      };

      try {
        setLoading(true);
        await addContactToList(contactData, 31);
        await sendNotification(formData.email, 65, {
          firstName: formData.firstName,
        });
        setSuccess(true);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          acceptNewsletter: false,
        });
      } catch (error) {
        setSubmissionError(
          "Une erreur est survenue lors de l'envoi du formulaire."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="form-container">
      <div className="form-page">
        {loading && <div className="loader">Envoi en cours...</div>}
        {!success ? (
          <form onSubmit={handleSubmit}>
            <div className="blue-bloc">
              <FormInput
                id="firstName"
                type="text"
                name="firstName"
                placeholder="Prénom*"
                value={formData.firstName}
                onChange={(e) =>
                  setFormData({ ...formData, firstName: e.target.value })
                }
                autoComplete="given-name"
                error={errors.firstName}
              />
              <FormInput
                id="lastName"
                type="text"
                name="lastName"
                placeholder="Nom*"
                value={formData.lastName}
                onChange={(e) =>
                  setFormData({ ...formData, lastName: e.target.value })
                }
                autoComplete="family-name"
                error={errors.lastName}
              />
              <FormInput
                id="email"
                type="email"
                name="email"
                placeholder="Adresse Email*"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                autoComplete="email"
                error={errors.email}
              />

              <div className="checkbox-container">
                <input
                  id="acceptNewsletter"
                  type="checkbox"
                  name="acceptNewsletter"
                  checked={formData.acceptNewsletter}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      acceptNewsletter: e.target.checked,
                    })
                  }
                />
                <label htmlFor="acceptNewsletter">
                  <span className="label-check">
                    J'accepte de recevoir la newsletter
                  </span>
                </label>
              </div>
              <div className="info-required">* : champs obligatoires</div>
              {submissionError && (
                <div className="error-message">{submissionError}</div>
              )}
            </div>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleCaptchaChange}
            />
            <button type="submit" disabled={loading}>
              {loading ? "Envoi en cours..." : "Confirmer ma participation"}
            </button>
          </form>
        ) : (
          <p>Contact ajouté et notification envoyée avec succès!</p>
        )}
      </div>
    </div>
  );
}

export default FormPage;
