import React, { useState } from "react";

function Question({ question, onAnswer }) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const handleOptionChange = (index) => {
    setSelectedOptions((prev) => {
      if (prev.includes(index)) {
        return prev.filter((option) => option !== index);
      } else {
        return [...prev, index];
      }
    });
  };

  const handleSubmit = () => {
    if (selectedOptions.length === 0) return; // Ne pas soumettre si aucune option n'est sélectionnée
    setSubmitted(true);
    setTimeout(() => {
      onAnswer(selectedOptions);
      setSubmitted(false);
      setSelectedOptions([]);
    }, 500); // Ajouter un délai pour l'effet de surbrillance
  };

  const isCorrect = (index) => question.correctAnswers.includes(index);
  const isSelected = (index) => selectedOptions.includes(index);

  return (
    <div>
      <h2>{question.question}</h2>
      <ul>
        {question.options.map((option, index) => (
          <li
            key={index}
            style={{
              color: submitted
                ? isCorrect(index)
                  ? isSelected(index)
                    ? "green"
                    : "orange"
                  : isSelected(index)
                  ? "red"
                  : "black"
                : "black",
            }}
          >
            <label>
              <input
                type="checkbox"
                checked={isSelected(index)}
                onChange={() => handleOptionChange(index)}
                disabled={submitted}
              />
              {option}
            </label>
          </li>
        ))}
      </ul>
      <button
        onClick={handleSubmit}
        disabled={submitted || selectedOptions.length === 0}
      >
        Valider
      </button>
    </div>
  );
}

export default Question;
