import React from "react";
import { useNavigate } from "react-router-dom";

function Result({ score, totalQuestions }) {
  const navigate = useNavigate();

  const getResultText = () => {
    const percentage = (score / totalQuestions) * 100;

    if (percentage === 100) {
      return "100% scrore parfait";
    } else if (percentage >= 80) {
      return "+80% c'est très bien";
    } else if (percentage >= 50) {
      return "+50% c'est bien !";
    } else {
      return "Il faudrait revoir quelques elements";
    }
  };

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/pdf";
    link.download = "lorem-ipsum.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="result">
      <h2>
        Score final: {score} / {totalQuestions}
      </h2>
      <p>{getResultText()}</p>
      <button onClick={handleDownload}>
        Télécharger le permis jeune mobilité
      </button>
      <button onClick={() => navigate("/formulaire")}>
        Aller au formulaire
      </button>
    </div>
  );
}

export default Result;
