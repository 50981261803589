// src/FormInput.js
import React from "react";
import PropTypes from "prop-types";

function FormInput({
  id,
  type,
  name,
  placeholder,
  value,
  onChange,
  autoComplete,
  error,
}) {
  return (
    <div className="form__group field">
      <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        className="form__field"
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />
      <label className="form__label" htmlFor={id}>
        {placeholder}
      </label>
      {error && <div className="error">{error}</div>}
    </div>
  );
}

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  error: PropTypes.string,
};

export default FormInput;
