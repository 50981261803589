//GameIntroCarousel.js
import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { Howl } from "howler";

const audioFiles = [
  null,
  "audio/intro.mp3",
  "audio/rules.mp3",
  "audio/first_character_hint.mp3",
];

const slides = [
  {
    title: "Hey, c’est aujourd’hui, le grand jour !",
    text: "Tu dois te rendre à l’école en bus pour la première fois. Tu te sens prêt ? Rassure-toi, tout va bien se passer, tout le monde est prêt à t’aider et à te donner les bons tuyaux",
    hasAudio: false,
  },
  {
    title: "",
    text: "Il est 7h45 du matin, la sonnerie retentit à 8h30, ça te laisse trois bons quarts d’heure pour arriver. N’oublie pas que tu passes le permis jeune mobilité ce matin à l’école : il va te permettre de prendre les transports en commun en toute sérénité. Si tu es bien curieux et attentif, tu vas l’avoir les doigts dans le nez, tu verras.",
    hasAudio: true,
  },
  {
    title: "",
    text: "Commence par chercher Max, le voisin d’en face, il va t’aider à monter dans le bus. Il doit déjà être à l’arrêt de bus, tu ne peux pas le louper, il est toujours avec son skate.",
    hasAudio: true,
  },
  {
    title: "Quelques recommandations",
    text: "<ul><li>Monte le son de ton casque.</li><li>Déplace-toi dans le quartier.</li><li>Interroge les personnages.</li></ul>",
    hasAudio: true,
  },
];

const GameIntroCarousel = ({ handleIntroClose, fadeOut }) => {
  const sliderRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sound, setSound] = useState(null);
  const [progress, setProgress] = useState(0);

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const handlePrevious = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleSlideChange = (index) => {
    setCurrentSlide(index);

    // Arrêter et supprimer l'audio actuel
    if (sound) {
      sound.stop();
      setSound(null);
    }

    // Jouer le nouvel audio
    if (audioFiles[index]) {
      const newSound = new Howl({
        src: [audioFiles[index]],
        onend: () => setProgress(0),
      });
      newSound.play();
      setSound(newSound);
    }
  };

  const handleIntroCloseWithAudioStop = () => {
    if (sound) {
      sound.stop();
      setSound(null);
    }
    handleIntroClose();
    document.querySelector(".indice-bloc").style.opacity = "1";
  };

  useEffect(() => {
    if (sound) {
      const interval = setInterval(() => {
        if (sound.playing()) {
          const progress = (sound.seek() / sound.duration()) * 100;
          setProgress(progress);
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [sound]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 1000, // Adjust the speed here
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => handleSlideChange(next),
  };

  return (
    <div className={`game-intro-dialogue ${fadeOut ? "fadeOut" : ""}`}>
      <div className="intro">
        <Slider ref={sliderRef} {...settings}>
          {slides.map((slide, index) => (
            <div key={index}>
              {slide.title && <h2>{slide.title}</h2>}
              <p dangerouslySetInnerHTML={{ __html: slide.text }}></p>
              {slide.hasAudio && (
                <div className="audio-progress">
                  <div
                    className="progress-bar"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </Slider>
        <div className="carousel-controls">
          {currentSlide > 0 && (
            <button onClick={handlePrevious}>⬅️ Précédent</button>
          )}
          {currentSlide < slides.length - 1 && (
            <button onClick={handleNext}>Suivant ➡️</button>
          )}

          {currentSlide === slides.length - 1 && (
            <button onClick={handleIntroCloseWithAudioStop}>
              Commencer le jeu
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default GameIntroCarousel;
