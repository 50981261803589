import React, { useEffect, useState } from "react";
import InteractiveImage from "./InteractiveImage";

function Game() {
  const [isDialogueOpen, setIsDialogueOpen] = useState(false); // Nouveau state pour le dialogue

  useEffect(() => {
    const element = document.querySelector(".game-container");
    let isDown = false;
    let startX, startY, scrollLeft, scrollTop;

    function handleMouseDown(e) {
      isDown = true;

      startX = e.pageX - element.offsetLeft;
      startY = e.pageY - element.offsetTop;
      scrollLeft = element.scrollLeft;
      scrollTop = element.scrollTop;
    }

    function handleMouseMove(e) {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - element.offsetLeft;
      const y = e.pageY - element.offsetTop;
      const walkX = x - startX;
      const walkY = y - startY;
      element.scrollLeft = scrollLeft - walkX;
      element.scrollTop = scrollTop - walkY;
    }

    function handleMouseUpOrLeave() {
      isDown = false;
    }

    // Ajouter des écouteurs d'événements pour mousedown, mouseup, mouseleave, mousemove
    if (element) {
      element.addEventListener("mousedown", handleMouseDown);
      element.addEventListener("mouseup", handleMouseUpOrLeave);
      element.addEventListener("mouseleave", handleMouseUpOrLeave);
      element.addEventListener("mousemove", handleMouseMove);
    }

    // Suppression des écouteurs d'événements lors du nettoyage
    return () => {
      if (element) {
        element.removeEventListener("mousedown", handleMouseDown);
        element.removeEventListener("mouseup", handleMouseUpOrLeave);
        element.removeEventListener("mouseleave", handleMouseUpOrLeave);
        element.removeEventListener("mousemove", handleMouseMove);
      }
    };
  }, []);

  return (
    <div className="game-container">
      <div className="game-layer">
        <InteractiveImage
          setIsDialogueOpen={setIsDialogueOpen}
          isDialogueOpen={isDialogueOpen}
        />
      </div>
    </div>
  );
}

export default Game;
