import React, { useContext, useEffect, useState } from "react";
import Game from "./Game";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import FormPage from "./FormPage";
import { LoadingProvider, LoadingContext } from "./LoadingContext";
import GameIntroCarousel from "./components/GameIntroCarousel";
import QuestionPage from "./QuestionPage";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Loader from "./LoaderApp";

function AppContent() {
  const { loading } = useContext(LoadingContext);
  const [isGameInteractive, setIsGameInteractive] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const location = useLocation();
  const [showBlank, setShowBlank] = useState(false);

  const handleIntroClose = () => {
    setFadeOut(true);
    setTimeout(() => {
      setIsGameInteractive(true);
    }, 500);
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      setShowBlank(true);
      const timeout = setTimeout(() => {
        setShowBlank(false);
      }, 1000); // Duration of the blank screen
      return () => clearTimeout(timeout);
    }
  }, [location]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className={`App ${!loading ? "fadeIn" : ""}`}>
          <TransitionGroup component={null}>
            {showBlank ? (
              <CSSTransition key="blank" classNames="blank" timeout={1000}>
                <div className="blank-page"></div>
              </CSSTransition>
            ) : (
              <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={1000}
              >
                <Routes location={location}>
                  <Route
                    path="/"
                    element={
                      <main
                        id="scrollable"
                        style={{ overflow: "auto", height: "100vh" }}
                      >
                        {!isGameInteractive && (
                          <GameIntroCarousel
                            handleIntroClose={handleIntroClose}
                            fadeOut={fadeOut}
                          />
                        )}
                        <Game isInteractive={isGameInteractive} />
                      </main>
                    }
                  />
                  <Route path="/questionnaire" element={<QuestionPage />} />
                  <Route path="/formulaire" element={<FormPage />} />
                </Routes>
              </CSSTransition>
            )}
          </TransitionGroup>
        </div>
      )}
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <LoadingProvider>
        <AppContent />
      </LoadingProvider>
    </BrowserRouter>
  );
}

export default App;
