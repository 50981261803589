//DialogueBox.js
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

function DialogueBox({ onClose, showButton, slides, stopAllAudio }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [audioProgress, setAudioProgress] = useState(0);
  const [slideHistory, setSlideHistory] = useState([]);
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const handleButtonClick = useCallback(() => {
    navigate("/questionnaire");
  }, [navigate]);

  const updateAudioSource = useCallback(() => {
    if (audioRef.current) {
      const audio = audioRef.current;
      stopAllAudio();

      if (slides[currentSlide].audioSrc) {
        audio.pause();
        audio.currentTime = 0;
        audio.src = slides[currentSlide].audioSrc;

        audio.onloadeddata = () => {
          audio.play().catch((error) => {
            console.error("Error playing audio:", error);
          });
        };

        audio.load();
      } else {
        audio.pause();
        audio.removeAttribute("src");
        audio.load();
      }
    }
  }, [currentSlide, slides, stopAllAudio]);

  useEffect(() => {
    updateAudioSource();
  }, [currentSlide, updateAudioSource]);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio) {
      const handleTimeUpdate = () => {
        setAudioProgress((audio.currentTime / audio.duration) * 100);
      };

      audio.addEventListener("timeupdate", handleTimeUpdate);
      return () => {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
      };
    }
  }, []);

  useEffect(() => {
    const indiceBloc = document.querySelector(".indice-bloc");
    if (indiceBloc) {
      indiceBloc.style.opacity = "0";
    }
    return () => {
      if (indiceBloc) {
        indiceBloc.style.opacity = "1";
      }
    };
  }, []);

  const handleNextSlide = useCallback(() => {
    const nextSlide = slides[currentSlide].nextSlide;
    if (nextSlide !== undefined) {
      setSlideHistory((prevHistory) => [...prevHistory, currentSlide]);
      setCurrentSlide(nextSlide);
    } else if (currentSlide < slides.length - 1) {
      setSlideHistory((prevHistory) => [...prevHistory, currentSlide]);
      setCurrentSlide((prev) => prev + 1);
    }
  }, [currentSlide, slides]);

  const handlePreviousSlide = useCallback(() => {
    if (slideHistory.length > 0) {
      const previousSlide = slideHistory[slideHistory.length - 1];
      setCurrentSlide(previousSlide);
      setSlideHistory((prevHistory) => prevHistory.slice(0, -1));
    }
  }, [slideHistory]);

  const handleChoice = useCallback(
    (nextSlide) => {
      setSlideHistory((prevHistory) => [...prevHistory, currentSlide]);
      setCurrentSlide(nextSlide);
    },
    [currentSlide]
  );

  const handleCloseDialogue = useCallback(() => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0; // Reset audio to start
    }
    stopAllAudio(); // Ensure all potential audio is stopped
    onClose();
  }, [onClose, stopAllAudio]);

  return (
    <div className="dialogue-box fadeInUp">
      <p>{slides[currentSlide].text}</p>
      {slides[currentSlide].audioSrc && (
        <div className="audio-progress">
          <div
            className="progress-bar"
            style={{ width: `${audioProgress}%` }}
          ></div>
        </div>
      )}
      <audio ref={audioRef}>
        <source src={slides[currentSlide].audioSrc} type="audio/mpeg" />
      </audio>

      <div className="carousel-controls">
        {slides[currentSlide].choices ? (
          slides[currentSlide].choices.map((choice, index) => (
            <button key={index} onClick={() => handleChoice(choice.nextSlide)}>
              {choice.text}
            </button>
          ))
        ) : (
          <>
            {slideHistory.length > 0 && (
              <button onClick={handlePreviousSlide}>⬅️ Précédent</button>
            )}
            {slides[currentSlide].nextSlide !== undefined ? (
              <button onClick={handleNextSlide}>Suivant ➡️</button>
            ) : (
              currentSlide < slides.length - 1 && (
                <button onClick={handleNextSlide}>Suivant ➡️</button>
              )
            )}
          </>
        )}
        {currentSlide === slides.length - 1 && showButton && (
          <button onClick={handleButtonClick}>Aller au formulaire</button>
        )}
        {currentSlide === slides.length - 1 && (
          <button onClick={handleCloseDialogue}>Fermer</button>
        )}
      </div>
    </div>
  );
}

export default DialogueBox;
