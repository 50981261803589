import React, { useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import Question from "./Question";
import Result from "./Result";
import questions from "../data/questions.json"; // Importez les questions depuis le fichier JSON

function Quiz() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const totalQuestions = questions.length;

  const handleAnswer = (selectedOptions) => {
    const correctAnswers = questions[currentQuestion].correctAnswers;
    const isCorrect =
      selectedOptions.length === correctAnswers.length &&
      selectedOptions.every((option) => correctAnswers.includes(option));

    if (isCorrect) {
      setScore(score + 1);
    }

    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResult(true);
    }
  };

  return (
    <div>
      {!showResult && (
        <h2>
          Question {currentQuestion + 1} / {totalQuestions}
        </h2>
      )}
      <SwitchTransition>
        <CSSTransition
          key={showResult ? "result" : "question"}
          timeout={600}
          classNames={showResult ? "fade-result" : "question"}
          unmountOnExit
        >
          <div>
            {showResult ? (
              <Result score={score} totalQuestions={totalQuestions} />
            ) : (
              <SwitchTransition>
                <CSSTransition
                  key={currentQuestion}
                  timeout={600}
                  classNames="question"
                  unmountOnExit
                >
                  <div>
                    <Question
                      question={questions[currentQuestion]}
                      onAnswer={handleAnswer}
                    />
                  </div>
                </CSSTransition>
              </SwitchTransition>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default Quiz;
